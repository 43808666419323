// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import {
  faAddressCard,
  faArrowDown,
  faArrowUp,
  faBezierCurve,
  faBuilding,
  faChartLine,
  faCheck,
  faCodeBranch,
  faCog,
  faEdit,
  faEraser,
  faEye,
  faEyeSlash,
  faExternalLinkAlt,
  faExclamationCircle,
  faFileCode,
  faFileDownload,
  faFileImport,
  faFileUpload,
  faHistory,
  faIdBadge,
  faImage,
  faInfoCircle,
  faListOl,
  faLock,
  faLockOpen,
  faMinus,
  faPlus,
  faProjectDiagram,
  faRedo,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faSitemap,
  faSpinner,
  faStore,
  faSync,
  faTasks,
  faTimes,
  faTrashAlt,
  faUserCheck,
  faUserCircle,
  faUserClock,
  faUserLock,
  faUserShield,
  faVial,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAddressCard,
  faArrowDown,
  faArrowUp,
  faBezierCurve,
  faBuilding,
  faChartLine,
  faCheck,
  faCodeBranch,
  faCog,
  faEdit,
  faEraser,
  faEye,
  faEyeSlash,
  faExternalLinkAlt,
  faExclamationCircle,
  faFileCode,
  faFileDownload,
  faFileImport,
  faFileUpload,
  faHistory,
  faIdBadge,
  faImage,
  faInfoCircle,
  faListOl,
  faLock,
  faLockOpen,
  faMinus,
  faPlus,
  faProjectDiagram,
  faRedo,
  faSave,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faSitemap,
  faSpinner,
  faStore,
  faSync,
  faTasks,
  faTimes,
  faTrashAlt,
  faUserCheck,
  faUserCircle,
  faUserClock,
  faUserLock,
  faUserShield,
  faVial
);
