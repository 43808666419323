import Axios from "axios";
import Parser from "./Parser";

class Utils {
  static capitalize(word) {
    word = word.replace("_", "");
    return word.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
  }

  static cloneObject(objToClone) {
    return JSON.parse(JSON.stringify(objToClone));
  }

  static decodeBase64(str) {
    return Buffer.from(str, "base64").toString("ascii");
  }

  static findItemInArray(items, propertyName, valueToFind) {
    return items.find(function (item) {
      return item[propertyName] === valueToFind;
    });
  }

  static findItemIndex(items, propertyName, valueToFind) {
    return items.findIndex(function (item) {
      return item[propertyName] === valueToFind;
    });
  }

  static findIndex(items, valueToFind) {
    return items.findIndex(function (item) {
      return item === valueToFind;
    });
  }

  static removeItemFromArray(list, valueToRemove) {
    if (!list) return [];

    var index = list.indexOf(valueToRemove);
    if (index !== -1) {
      list.splice(index, 1);
    }
    return list;
  }

  static removeListItemByVal(list, propertyName, valueToRemove) {
    return list.filter((item) => {
      return item[propertyName] !== valueToRemove;
    });
  }

  static removeListItemByIntVal(list, propertyName, valueToRemove) {
    return list.filter((item) => {
      return parseInt(item[propertyName]) !== parseInt(valueToRemove);
    });
  }

  static renameObjectKey(oldObj, oldKey, newKey) {
    const newObj = {};

    Object.keys(oldObj).forEach((key) => {
      const value = oldObj[key];

      if (key === oldKey) {
        newObj[newKey] = value;
      } else {
        newObj[key] = value;
      }
    });

    return newObj;
  }

  static getQueryParams() {
    const params = window.location.search.replace("?", "").split("&");
    let result = {};

    params.forEach(function (param) {
      const parts = param.split("=");
      result[parts[0]] = parts[1];
    });

    return result;
  }

  static getQueryParam(key) {
    const params = window.location.search.replace("?", "").split("&");
    let paramVal = "";

    params.forEach(function (param) {
      const parts = param.split("=");

      if (parts[0] === key) {
        paramVal = parts[1];
      }
    });

    return paramVal;
  }

  static getIdParam() {
    const params = window.location.pathname.split("/");

    return params.pop();
  }

  static getStatusBadge(status) {
    switch (status) {
      case "locked":
        return "danger";
      case "office_bearer":
        return "info";
      default:
        return "success";
    }
  }

  static ensureJson(response) {
    if (typeof response.data === "string") {
      throw new Error(
        "Response does not seem to be valid json. Check the developer console"
      );
    }

    if (!response.headers.hasOwnProperty("content-type")) {
      throw new Error("Response content type is not 'application/json'");
    }

    if (!response.headers["content-type"].includes("application/json")) {
      throw new Error("Response content type is not 'application/json'");
    }
  }

  static showAjaxErrorToast(selfRef, axiosError) {
    if (Axios.isCancel(axiosError)) return;

    const msg = Parser.parseErrorResponse(axiosError);

    if (msg === "" || msg === undefined) return;

    selfRef.props.addToast(msg, {
      appearance: "error",
      autoDismiss: true,
    });
  }

  /**
   * Remove duplicates from array.
   * [1, 1, 2, 3, 3] will become [1, 2, 3]
   * @param {*} arr
   * @returns
   */
  static unique(arr) {
    return arr
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort(function (a, b) {
        return a - b;
      });
  }
  /**
   * Browsers like Brave does not allow GA, Sentry etc. so we disable
   * 3rd party services. Also disable for development
   */
  static thirdPartyEnabled() {
    if (process.env.REACT_APP_ENVIRONMENT === "development") return false;

    //Check for the brave browser
    return window.navigator.brave !== undefined &&
      window.navigator.brave.isBrave.name === "isBrave"
      ? false
      : true;
  }
}
export default Utils;
