class Auth {
  static getJwtToken() {
    const jwt = localStorage.getItem("jwtToken");

    if (jwt === "") Auth.redirectToLogin(true, "no-token");

    return jwt;
  }

  static getUserState() {
    let user = Auth.initialUserState();

    user.loggedIn = localStorage.getItem("jwtToken") !== "";
    user.jwtToken = user.loggedIn ? localStorage.getItem("jwtToken") : "";
    user.jwtExpiresAt = user.loggedIn
      ? localStorage.getItem("jwtExpiresAt")
      : null;

    return user;
  }

  static isLoggedIn() {
    const userState = Auth.getUserState();

    if (userState.loggedIn) {
      const now = new Date();
      const expires = new Date(userState.jwtExpiresAt);

      if (now > expires) {
        Auth.logout(true, "expired-token");
      }
    }

    return userState.loggedIn;
  }

  static ensureLoggedIn() {
    if (!Auth.isLoggedIn()) Auth.logout(true, "invalid-token");
  }

  static login(jwtToken, expiresAt, jwtId) {
    localStorage.setItem("jwtToken", jwtToken);
    localStorage.setItem("jwtExpiresAt", expiresAt);
    localStorage.setItem("jwtId", jwtId);
  }

  static logout(redirect = false, reason = null) {
    localStorage.setItem("jwtToken", "");
    localStorage.setItem("jwtExpiresAt", null);
    localStorage.setItem("jwtId", null);
    Auth.redirectToLogin(redirect, reason);
  }

  static redirectToLogin(redirect = false, reason = null) {
    let url = "/login";

    if (redirect || reason) url = url + "?";
    if (redirect) url = url + "redirect=" + window.location.pathname;
    if (redirect && reason) url = url + "&";
    if (reason) url = url + "reason=" + reason;

    window.location.replace(url);
  }

  static initialUserState() {
    return {
      loggedIn: false,
      jwtToken: null,
      jwtExpiresAt: null,
      jwtId: null,
    };
  }
}

export default Auth;
