import Auth from "./Auth";

class Parser {
  /**
   * Parse the server response for an error message.
   * There are different error responses returned from the the different endpoints
   * so we have to extract the message from different places in the payload
   * @param {*} error
   */
  static parseErrorResponse(error, redirectOn401 = true) {
    let message = "An unknown error occured";

    if (typeof error === "string") {
      return error;
    }

    if (!error.response && error.hasOwnProperty("message")) {
      return error.message;
    }

    if (error.hasOwnProperty("response")) {
      const response = error.response;

      if (redirectOn401 && response.status === 401) {
        Auth.logout(true, "invalid-token");
      }

      if (response.hasOwnProperty("data")) {
        let msg = this.extractErrorFromData(response.data);
        if (msg !== false) {
          return msg;
        }
      }

      if (response.hasOwnProperty("statusText")) {
        return response.statusText + ".\nResponse Code: " + response.status;
      }
    }

    return message;
  }

  static extractErrorFromData(data) {
    if (
      data.hasOwnProperty("description") &&
      data.hasOwnProperty("error") &&
      data.hasOwnProperty("status_code")
    ) {
      return data.description;
    }

    if (data.hasOwnProperty("errors")) {
      return data.errors[0].message;
    }

    if (data.hasOwnProperty("description") && data.hasOwnProperty("error")) {
      return data.description + "\nResponse Code: " + data.status_code;
    }

    const msg = data.message;

    if (typeof msg === "string") {
      return msg;
    } else if (msg === undefined) {
      return "The server returned an error that could not be interpreted. Check the console window for more info.";
    } else {
      const field = Object.keys(msg)[0];
      return "Field [" + field + "]: " + msg[field];
    }
  }
}

export default Parser;
